<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:statusFilter', val)"
            placeholder="Select Status.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Created by</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="createdByFilter"
            :options="employeeOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:createdByFilter', val)"
            placeholder="Select Created By.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Referred by</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="referredByFilter"
            :options="employeeOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:referredByFilter', val)"
            placeholder="Select Referred By.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Recruited by</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="recruiterByFilter"
            :options="employeeOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:recruiterByFilter', val)"
            placeholder="Select Recruited By.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Source</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="sourceByFilter"
            :options="candidateSourceOption"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:sourceByFilter', val)"
            placeholder="Select Source.."
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (h) => h("feather-icon", { props: { size: "14", icon: "XIcon" } }),
  },
  OpenIndicator: {
    render: (h) =>
      h("feather-icon", {
        props: { size: "15", icon: "ChevronDownIcon" },
        class: "open-indicator",
      }),
  },
});

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
  },
  props: {
    statusFilter: {
      type: [Number, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    createdByFilter: {},
    referredByFilter: {},
    recruiterByFilter: {},
    sourceByFilter: {},
    employeeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      candidateSourceOption: [
        { label: "Naukri", code: "naukri" },
        { label: "Indeed", code: "indeed" },
        { label: "Hire a star", code: "hire_a_star" },
        { label: "Linkedin", code: "linkedin" },
        // { label: "DCKAP Careers", code: "DCKAP Careers" },
        // { label: "Other", code: "other" },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
